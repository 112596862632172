// pages/404.tsx

import { useEffect, useState } from "react";
import { useRouter } from "next/router";
export default function NotFound() {
  const router = useRouter();
  const [isNotFound, setIsNotFound] = useState(false);
  useEffect(() => {
    if (router.asPath.startsWith("/images")) {
      router.replace(router.asPath);
    } else {
      setIsNotFound(true);
    }
  }, [router]);
  if (isNotFound) return <h1>404 - Page Not Found</h1>;
  return null;
}